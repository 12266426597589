const getClosest = require("../utils/get-closest");

module.exports.start = () => {
  document.addEventListener("DOMContentLoaded", () => {
    const appNames = document.querySelectorAll(".app-envs ul li span");
    const wizard = document.getElementById("wizard-modal");
    if (wizard !== null) {
      document.getElementById("new_app_name").addEventListener("input", (e) => {
        appNames.forEach((appName) => (appName.innerText = e.target.value));
      });

      document
        .getElementById("multiple_envs")
        .addEventListener("change", (e) => {
          document
            .querySelector(".app-envs")
            .classList.toggle("db", e.target.checked);
          getClosest(e.target, ".unified-modal").querySelector(
            ".modal-button-action"
          ).innerText = e.target.checked ? "Create apps" : "Create app";
        });
    }
  });
};
