class EventsTokenProvider {
  constructor({ instanceId } = {}) {
    this.instanceId = instanceId;
  }

  async fetchToken() {
    const response = await fetch(
      `/beams/instances/${this.instanceId}/debug_console_token_provider`,
      {
        method: "GET",
        mode: "cors",
        credentials: "same-origin",
      }
    );
    const { token } = await response.json();
    return token;
  }
}

export default EventsTokenProvider;
