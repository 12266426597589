const setLoading = (button) => {
  button.setAttribute("disabled", true);
  button.querySelector(".ready").classList.add("dn");
  button.querySelector(".loading").classList.add("flex");
};

const unsetLoading = (button) => {
  button.removeAttribute("disabled");
  button.querySelector(".ready").classList.remove("dn");
  button.querySelector(".loading").classList.remove("flex");
};

export { setLoading, unsetLoading };
