module.exports.start = () => {
  document.addEventListener("DOMContentLoaded", () => {
    const clickableElements = [].slice.call(
      document.querySelectorAll(".map-key-press-to-element-click")
    );

    clickableElements.forEach((element) => {
      const keyToWatch = element.dataset.key;
      if (keyToWatch !== undefined) {
        window.addEventListener("keyup", (e) => {
          if (e.key === keyToWatch) {
            // Only click element if it’s visible
            // otherwise pressing Esc clicks things like hidden modals
            if (element.offsetParent !== null) {
              element.click();
            }
          }
        });
      } else {
        console.error("Element is missing data-key attribute", element);
      }
    });
  });
};
