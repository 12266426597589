import { createFlash } from "../flash";
import { updateUsageLine } from "../fetch-usage";

const formatUnixTimestamp = (date, options) =>
  new Date(parseInt(date) * 1000).toLocaleString("en-us", options);

const formatDateString = (dateStr, options) =>
  new Date(dateStr).toLocaleString("en-us", options);

const formatData = (data, key) =>
  data.map((point) => {
    return [
      formatUnixTimestamp(point[0]),
      point[key === "peak_connections" ? 2 : 1],
    ];
  });

const buildSeries = (data, series, type) => {
  if (type === "historical") {
    return series.map((serial) => ({
      name: serial.label ? serial.label : serial.key,
      data: data[serial.key],
    }));
  }
  if (type === "overview") {
    return series.map((serial) => ({
      name: serial.label ? serial.label : serial.key,
      data: formatData(data, serial.key),
    }));
  }
};

const toggleLoading = () => {
  const spinners = [].slice.call(document.querySelectorAll(".loading-chart"));
  spinners.forEach((spinner) => {
    spinner.classList.toggle("flex");
    spinner.classList.toggle("dn");
  });
};

const fetchData = async (endpoint) => {
  const response = await fetch(endpoint, {
    method: "GET",
  });
  return await response.json();
};

const updateGraphs = async (chartsToLoad, endpoint, period) => {
  let seriesForChart;
  try {
    if (chartsToLoad.length === 0) {
      return;
    }
    const payload = await fetchData(endpoint);

    if (period) {
      let startTimestamp = "";
      let endTimestamp = "";

      const firstChart = chartsToLoad[0];

      chartsToLoad.forEach((chartToLoad) => {
        const chart = Chartkick.charts[chartToLoad.chartID];
        if (chart) {
          seriesForChart = buildSeries(payload, chartToLoad.series, "historical");
          chart.updateData(seriesForChart);
        }
      });

      if (seriesForChart.length > 0 && seriesForChart[0].data.length >= 2) {
        const firstSeries = seriesForChart[0].data;
        const firstValue = firstSeries[0];
        const lastValue = firstSeries[firstSeries.length - 1];

        startTimestamp = firstValue[0];
        endTimestamp = lastValue[0];
      }

      setSubTitle(period, startTimestamp, endTimestamp);
    } else {
      chartsToLoad.forEach((chartToLoad) => {
        const chart = Chartkick.charts[chartToLoad.chartID];

        if (chart) {
          seriesForChart = buildSeries(
            payload.data,
            chartToLoad.series,
            "overview"
          );
          chart.updateData(seriesForChart);
        }
      });
    }
  } catch (error) {
    console.error(error);
    createFlash("error", "Error fetching account statistics for this period");
  }
  toggleLoading();
};

const setSubTitle = (period, startTimestamp, endTimestamp) => {
  const niceDateFormat = {
    month: "short",
    day: "numeric",
  };
  const startDate = formatDateString(startTimestamp, niceDateFormat);
  const endDate = formatDateString(endTimestamp, niceDateFormat);
  const periodHeading = [].slice.call(
    document.querySelectorAll(".period-heading")
  )[0];

  periodHeading.innerText = `${startDate} ${
    period !== "day" ? `- ${endDate}` : ""
  }`;
};

const updateURL = (period, offset) => {
  const params = new URLSearchParams(window.location.search);
  params.set("period", period);
  if (offset) {
    params.set("offset", offset);
  } else {
    params.delete("offset");
  }
  window.history.replaceState(
    "",
    "",
    `${window.location.pathname}?${params.toString()}`
  );
};

const fetchAllStats = (
  chartsToLoad,
  endpoint,
  initialPeriod,
  initialOffset
) => {
  const defaultURLParams = {};
  if (window.loggable_id && window.loggable_type) {
    defaultURLParams.loggable_id = window.loggable_id;
    defaultURLParams.loggable_type = window.loggable_type;
  }
  const makeURL = (period, offset) => {
    const url = new URL(`${window.location.origin}${endpoint}`);
    for (const [paramName, paramValue] of Object.entries(defaultURLParams)) {
      url.searchParams.set(paramName, paramValue);
    }
    url.searchParams.set("period", period);
    url.searchParams.set("offset", offset);
    return url.toString();
  };

  const initialURL = makeURL(initialPeriod, initialOffset);

  window.addEventListener("load", async () => {
    updateGraphs(chartsToLoad, `${initialURL}`, initialPeriod);

    const nextButtonForm = document.getElementById("next-period-form");

    const periodSwitches = [].slice.call(
      document.querySelectorAll(".stats-period-switch")
    );
    periodSwitches.forEach((periodSwitch) => {
      periodSwitch.addEventListener("change", (e) => {
        const period = e.target.value;
        const offset = 0;
        toggleLoading();

        const nextURL = makeURL(period, offset);
        updateGraphs(chartsToLoad, `${nextURL}`, period);
        updateURL(period, false);

        document.getElementById("previous-period").value = period;
        document.getElementById("previous-period-offset").value = 1;
        document.getElementById("next-period").value = period;
        nextButtonForm.style.display = "none";
        const prevButton = document.getElementById("previous-button");
        prevButton.title = `${prevButton.title.split(" ")[0]} ${period}`;
      });
    });

    const offsetForms = [].slice.call(
      document.querySelectorAll(".offset-form")
    );
    offsetForms.forEach((offsetForm) => {
      offsetForm.addEventListener("submit", (e) => {
        e.preventDefault();
        const period = e.target[0].value;
        const offset = e.target[1].value;
        toggleLoading();

        const nextURL = makeURL(period, offset);
        updateGraphs(chartsToLoad, `${nextURL}`, period);
        updateURL(period, offset);

        document.getElementById("previous-period-offset").value =
          parseInt(offset) + 1;
        if (offset > 0) {
          nextButtonForm.style.display = "block";
        } else {
          nextButtonForm.style.display = "none";
        }
        document.getElementById("next-period-offset").value = Math.max(
          parseInt(offset) - 1,
          0
        );
      });
    });
  });
};

const fetchOverviewStats = (chartsToLoad, endpoint, level, id) => {
  const initialURL = new URL(`${window.location.origin}${endpoint}`);
  initialURL.searchParams.set("loggable_id", id);
  initialURL.searchParams.set("loggable_type", level);

  window.addEventListener("load", async () => {
    updateGraphs(chartsToLoad, `${initialURL}`);
  });
  document.querySelectorAll('input[name="period"]').forEach((switchEl) => {
    switchEl.addEventListener("change", (e) => {
      if (e.target.value !== "live") {
        updateGraphs(chartsToLoad, `${initialURL}`);
      }
    });
  });
};

const initRealtimeStats = (
  level,
  live,
  pusherOptions,
  chartsToLoad,
  triggerOnLoad = false
) => {
  const realtimeStats = new RealtimeStats(
    level,
    live,
    pusherOptions,
    chartsToLoad
  );

  document.querySelectorAll('input[name="period"]').forEach((switchEl) => {
    switchEl.addEventListener("change", (e) => {
      if (e.target.value === "live") {
        realtimeStats.init();
      } else {
        realtimeStats.stop();
      }
    });
  });

  if (triggerOnLoad) realtimeStats.init();
};

class RealtimeStats {
  constructor(level, live, pusherOptions, chartsToLoad) {
    this.level = level;
    this.live = live;
    this.pusherOptions = pusherOptions;
    this.chartsToLoad = chartsToLoad;
    this.pusher = null;
    this.chartsData = {};
  }

  async init() {
    if (this.live) {
      const { default: Pusher } = await import(
        /* webpackChunkName: "pusher-js" */ "pusher-js"
      );
      this.pusher = new Pusher(this.live.subscription_key, this.pusherOptions);
      this.chartsToLoad.forEach(({ chartID }) => {
        const channel = this.pusher.subscribe(
          this.live[`${this.level}_${chartID}_channel`]
        );
        channel.bind("stats", (data) => {
          this.process(chartID, data);
          this.updateTotals(chartID, data);
        });
        this.chartsData[chartID] = Chartkick.charts[chartID].getData();
      });
    } else {
      createFlash("error", "Realtime stats unavailable");
    }
  }

  stop() {
    if (this.pusher !== null) {
      this.chartsToLoad.forEach(({ chartID }) => {
        this.pusher.unsubscribe(this.live[`${this.level}_${chartID}_channel`]);
      });
    }
  }

  updateTotals(type, data) {
    const total = type === "messages" ? data.day_sum : data.day_max;
    if (this.level === "app") {
      document.getElementById(`usage-block-figure-${type}`).innerText = total;
    }
    if (this.level === "account") {
      const usageLineEl = document.getElementById(`usage-line-${type}`);
      updateUsageLine(usageLineEl, total);
    }
  }

  process(chartID, newData) {
    // newData = {
    //   "value": {
    //     "1599751950": 0
    //   },
    //   "day_max": 0,
    //   "day_sum": 0
    // }
    const [newDataAsArray] = Object.entries(newData.value);
    const formatted = [
      formatUnixTimestamp(newDataAsArray[0]),
      newDataAsArray[1],
    ];
    if (this.chartsData[chartID][0].data.length > 300) {
      this.chartsData[chartID][0].data.shift();
    }
    this.chartsData[chartID][0].data.push(formatted);
    Chartkick.charts[chartID].updateData(this.chartsData[chartID]);
  }
}

export { fetchAllStats, fetchOverviewStats, initRealtimeStats };
