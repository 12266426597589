const getClosest = require("../utils/get-closest");

module.exports.start = () => {
  document.addEventListener("DOMContentLoaded", () => {
    const modalActionButtons = [].slice.call(
      document.querySelectorAll(".modal-button-action")
    );

    if (modalActionButtons.length > 0) {
      modalActionButtons.forEach((button) => {
        const [add] = [].slice.call(
          getClosest(button, ".unified-modal").querySelectorAll(".add-icon")
        );

        if (add !== undefined) {
          button.addEventListener("mouseenter", (e) => {
            add.classList.add("rotate-add-icon");
          });

          button.addEventListener("mouseleave", (e) => {
            add.classList.remove("rotate-add-icon");
          });
        }
      });
    }
  });
};
