module.exports.start = () => {
  document.addEventListener("DOMContentLoaded", () => {
    const disabledLinks = [].slice.call(
      document.querySelectorAll(".disabled--link")
    );

    disabledLinks.forEach((link) => {
      link.addEventListener("click", (e) => {
        if (e.target.dataset.disabled === "true") {
          e.preventDefault();
        }
      });
    });
  });
};
