const getClosest = require("../utils/get-closest");

module.exports.start = () => {
  document.addEventListener("DOMContentLoaded", () => {
    const snippetLanguageSelectors = [].slice.call(
      document.querySelectorAll(".snippet-language-selector")
    );

    const hiddenItems = (items) =>
      items.filter((item) => item.classList.contains("active") === false);
    const activeItem = (items) =>
      items.filter((item) => item.classList.contains("active") === true);

    if (snippetLanguageSelectors.length > 0) {
      snippetLanguageSelectors.forEach((snippetLanguageSelector) => {
        snippetLanguageSelector.addEventListener("click", (e) => {
          e.preventDefault();
          const parentLink =
            e.target.tagName.toLowerCase() === "a"
              ? e.target
              : getClosest(e.target, "a");

          const menuWrap = getClosest(e.target, "ul");
          const snippetsContainer = getClosest(e.target, ".snippet-component");
          const siblingSnippetLanguageSelectors = [].slice.call(
            menuWrap.querySelectorAll(".snippet-language-selector")
          );
          if (parentLink.classList.contains("active")) {
            hiddenItems(siblingSnippetLanguageSelectors).map((item) => {
              item.classList.toggle("show");
            });
            menuWrap.setAttribute("aria-expanded", true);
          } else {
            activeItem(siblingSnippetLanguageSelectors).map((item) => {
              item.classList.remove("active");
            });
            hiddenItems(siblingSnippetLanguageSelectors).map((item) => {
              item.classList.remove("show");
            });
            parentLink.classList.add("active");
            menuWrap.setAttribute("aria-expanded", false);
          }

          [].slice
            .call(snippetsContainer.querySelectorAll(".snippet-block"))
            .forEach((snippet) => snippet.classList.remove("active"));
          document
            .getElementById(parentLink.dataset.target)
            .classList.add("active");
          parentLink.blur();
        });
      });
    }
  });
};
