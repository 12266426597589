import { Controller } from "stimulus";
const getClosest = require("../utils/get-closest");

export default class extends Controller {
  static values = {
    initialLimit: Number,
    max: Number,
  };
  static targets = [
    "increaseMessage",
    "increaseValue",
    "decreaseMessage",
    "decreaseValue",
    "sameMessage",
    "unsetMessage",
    "maxMessage",
    "saveButton",
  ];

  limitChanged({ target }) {
    let message;
    const newValue = parseInt(target.value);
    this.resetState();

    if (this.maxValue !== 0 && newValue > this.maxValue) {
      message = this.maxMessageTarget;
      this.saveButtonTarget.disabled = true;
    } else if (newValue > this.initialLimitValue) {
      message = this.increaseMessageTarget;
      this.showNewLimit(this.increaseValueTarget, newValue);
    } else if (newValue < this.initialLimitValue) {
      message = this.decreaseMessageTarget;
      this.showNewLimit(this.decreaseValueTarget, newValue);
    } else if (newValue === this.initialLimitValue) {
      message = this.sameMessageTarget;
    } else {
      message = this.unsetMessageTarget;
    }
    this.toggleMessage(message, true);
  }

  toggleMessage(el, show) {
    el.classList.toggle("flex", show);
    el.classList.toggle("dn", !show);
  }

  showNewLimit(el, value) {
    el.innerText = value.toLocaleString();
  }

  resetState() {
    [
      this.increaseMessageTarget,
      this.decreaseMessageTarget,
      this.sameMessageTarget,
      this.unsetMessageTarget,
      this.maxMessageTarget,
    ].forEach((el) => {
      this.toggleMessage(el, false);
    });
    this.saveButtonTarget.disabled = false;
  }
}
