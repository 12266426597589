const getClosest = require("../utils/get-closest");

module.exports.start = () => {
  document.addEventListener("DOMContentLoaded", () => {
    const moreToggles = [].slice.call(
      document.querySelectorAll(".more-toggle")
    );

    moreToggles.forEach((toggle) => {
      const parentGroup = toggle.dataset.group || "ul";
      toggle.addEventListener("click", (e) => {
        if (
          getClosest(e.target, parentGroup).querySelectorAll(".dn").length === 0
        ) {
          e.target.firstChild.textContent = e.target.dataset.expand;
          e.target.firstElementChild.style.transform = "rotate(0deg)";
        } else {
          e.target.firstChild.textContent = e.target.dataset.contract;
          e.target.firstElementChild.style.transform = "rotate(180deg)";
        }
        getClosest(e.target, parentGroup)
          .querySelectorAll(".to-hide")
          .forEach((listEl) => {
            listEl.classList.toggle("dn");
          });
      });
    });
  });
};
