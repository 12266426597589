module.exports.start = () => {
  document.addEventListener("DOMContentLoaded", () => {
    const createButtons = [].slice.call(
      document.querySelectorAll(".btn-create-app")
    );
    const [ghost] = [].slice.call(document.querySelectorAll(".ghost-icon"));

    if (createButtons.length > 0 && ghost !== undefined) {
      createButtons.forEach((createButton) => {
        createButton.addEventListener("mouseenter", (e) => {
          ghost.classList.add("shrink");
        });

        createButton.addEventListener("mouseleave", (e) => {
          ghost.classList.remove("shrink");
        });
      });
    }
  });
};
