const start = () => {
  document.addEventListener("DOMContentLoaded", () => {
    const { matches: prefersDarkScheme } = window.matchMedia(
      "(prefers-color-scheme: dark)"
    );
    const currentTheme = localStorage.getItem("theme");
    const modeToggle = document.getElementById("color-mode");

    if (currentTheme == "dark") {
      document.body.classList.toggle("dark-mode");
    } else if (currentTheme == "light") {
      document.body.classList.toggle("light-mode");
    } else if (prefersDarkScheme) {
      document.body.classList.toggle("dark-mode");
    } else {
      document.body.classList.toggle("light-mode");
    }

    // using not light, because before choosing a preference
    // currentTheme is null
    if (prefersDarkScheme && currentTheme != "light") {
      modeToggle.checked = true;
    } else if (currentTheme == "dark") {
      modeToggle.checked = true;
    }

    if (modeToggle) {
      modeToggle.addEventListener("click", (e) => {
        const setDarkMode = e.target.checked;

        document.body.classList.toggle("dark-mode", setDarkMode);
        document.body.classList.toggle("light-mode", !setDarkMode);

        const theme = setDarkMode ? "dark" : "light";
        localStorage.setItem("theme", theme);
      });
    }
  });
};

export { start };
