import { createFlash } from "../flash";

const enableButton = () => {
  const button = document.getElementById("wait-button");
  button.classList.remove("disabled--link");
  button.innerHTML = "Continue";
  createFlash(
    "success",
    "Device registered! 🎉 Now continue to the next step."
  );
  button.dataset.disabled = false;
};

const start = (endpoint, platform) => {
  window.addEventListener("DOMContentLoaded", () => {
    const waitingForDevice = setInterval(() => {
      fetch(endpoint, {
        method: "GET",
        mode: "cors",
        credentials: "same-origin",
      })
        .then((response) => response.json())
        .then((totals) => {
          if (totals.error) {
            createFlash("error", totals.error);
            console.error(`Device totals endpoint returned: ${err}`);
          }

          if (totals[platform] > 0) {
            clearInterval(waitingForDevice);
            enableButton();
          }
        })
        .catch((err) => {
          createFlash("error", err);
          console.error(`Error fetching device totals: ${err}`);
        });
    }, 3000);
  });

  const search = new URLSearchParams(window.location.search);
  if (search.get("skip") !== null) {
    enableButton();
  }
};

export { start };
