import { initPusherJS } from "../../utils/init-pusher-js";
import * as eventCreator from "./event-creator";
import * as filters from "./filters";
import EventList from "./event-list";
import getClosest from "../../utils/get-closest";
import { createFlash } from "../flash";

const findButtonClicked = (target) =>
  target.tagName.toLowerCase() === "button"
    ? target
    : getClosest(target, "button");

const start = (accountId, appId, logTypes) => {
  window.addEventListener("DOMContentLoaded", async () => {
    const pusher = await initPusherJS();
    startEventList(appId, logTypes, pusher);
    eventCreator.start(accountId);
  });
};
export { start };

function startEventList(appId, logTypes, pusher) {
  let eventList = new EventList(logTypes);
  let handleLog = eventList.processLog.bind(eventList);

  filters.start(appId, handleLog, pusher, eventList.getLogList.bind(eventList));

  for (var key in logTypes) {
    // subscribe to all log-type channels by default and bind handleLog
    // as the handler for the log_message events
    pusher
      .subscribe(`private-logger-${appId}-${key}`)
      .bind("log_message", handleLog);
  }

  pusher.connection.bind("failed", () => {
    createFlash("error", "Failed to connect");
  });

  document.getElementById("clear_console").addEventListener("click", (e) => {
    eventList.clear();
  });

  document.getElementById("pause-logs").addEventListener("click", (e) => {
    eventList.toggleLogging(findButtonClicked(e.target));
  });

  document.getElementById("resume-logs").addEventListener("click", (e) => {
    eventList.toggleLogging(findButtonClicked(e.target));
  });
}
