const updateUsageLine = (component, total) => {
  const limit = parseInt(component.dataset.limit);
  const usagePercentage = Math.min(((total / limit) * 100).toFixed(), 100);
  const usageLine = component.querySelector(".usage-line-amount");
  if (usagePercentage < 70) {
    // already with the correct color
  } else if (usagePercentage < 100) {
    usageLine.classList.add("bg-pumpkin-light");
  } else {
    usageLine.classList.add("bg-radish-light");
  }
  usageLine.style.width = `${usagePercentage}%`;
  component.querySelector(
    ".usage-number"
  ).innerText = new Intl.NumberFormat().format(total);
  component.querySelector(
    ".usage-percentage"
  ).innerText = `${usagePercentage}%`;

  if (usagePercentage < 70) {
    component.querySelector(".usage-over-limit").classList.add("dn");
    component.querySelector(".usage-close-to-limit").classList.add("dn");
  } else if (usagePercentage < 100) {
    component.querySelector(".usage-over-limit").classList.add("dn");
    component.querySelector(".usage-close-to-limit").classList.remove("dn");
  } else {
    component.querySelector(".usage-over-limit").classList.remove("dn");
    component.querySelector(".usage-close-to-limit").classList.add("dn");
  }
};

const start = () => {
  document.addEventListener("DOMContentLoaded", () => {
    const usageComponent = [].slice.call(
      document.querySelectorAll(".fetch-usage")
    );

    usageComponent.forEach((component) => {
      fetch(component.dataset.usageEndpoint, {
        method: "GET",
        mode: "cors",
        credentials: "same-origin",
      })
        .then((response) => response.json())
        .then((data) => {
          const nonNullUsage = data.filter((d) => d[1] !== null);
          const usageTotal = nonNullUsage[nonNullUsage.length - 1][1];
          updateUsageLine(component, usageTotal);
        })
        .catch((err) => {
          console.error(`Error updating usage line: ${err}`);
        });
    });
  });
};

export { updateUsageLine, start };
