let statementsPageNum = 1;

function formatDate(dateString) {
  const date = new Date(dateString);
  return date.toLocaleString("en-GB", {
    month: "long",
    day: "numeric",
    year: "numeric",
    timeZone: "UTC",
  });
}

async function fetchStatements(pageNum, accountID) {
  const loadingRow = document.getElementById("loading-row");
  const errorRow = document.getElementById("error-row");
  const moreRow = document.getElementById("more-row");

  loadingRow.classList.remove("dn");

  const response = await fetch(`/chargify/statements?page=${pageNum}`);
  const data = await response.json();

  if (data.errors) {
    errorRow.classList.remove("dn");
  } else {
    const { statements } = data;
    if (statements.length == 0) {
      moreRow.classList.add("dn");
    } else {
      moreRow.classList.remove("dn");
    }
    loadingRow.classList.add("dn");

    statements.forEach((statement) => {
      let settledColumnHtml = "";
      if (
        statement.settled_at &&
        new Date(statement.settled_at).getTime() < new Date().getTime()
      ) {
        settledColumnHtml = `✔
        Settled on 
            ${formatDate(statement.settled_at)}`;
      } else {
        settledColumnHtml = "✗ Not settled";
      }

      const tableRow = `<tr><td class="pv3">${formatDate(
        statement.created_at
      )}</td><td class="pv3">Statement generated</td><td class="pv3 flex items-center">${settledColumnHtml}</td><td class="pv3"><a href="/chargify/statement/${accountID}?statement_id=${
        statement.id
      }" class="sans-serif f7 lh-title tc nowrap bg-snow hover-bg-snow-light focus-bg-starflower-light active-bg-snow-dark ba bw1 b--snow focus-b--snow-light hover-b--snow-light active-b--snow-dark pointer black pv1 ph2 fw6 dib no-underline br2">Download</a></td></td></tr>`;

      document
        .querySelector("#statements-table tbody")
        .insertAdjacentHTML("beforeend", tableRow);
    });

    statementsPageNum += 1;
  }
}

const fetchChargifyStatements = (accountID) => {
  document.addEventListener("DOMContentLoaded", () => {
    fetchStatements(statementsPageNum, accountID);

    document.getElementById("more-row").addEventListener("click", (e) => {
      fetchStatements(statementsPageNum, accountID);
    });
  });
};

export { fetchChargifyStatements };
