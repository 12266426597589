const getClosest = require("../../utils/get-closest");

let searchQueries = [];

function start(appId, bindFn, pusher, getLogList) {
  document.getElementById("search").addEventListener("keyup", (e) => {
    const logList = getLogList();
    const keyCode = e.keyCode || e.which;

    // Return if arrow keys pressed
    if ([37, 38, 39, 40].indexOf(keyCode) > -1) return;
    searchQueries = e.target.value.split(" ").filter((s) => s === 0 || s);

    Object.keys(logList).forEach((key) => {
      logList[key].forEach((sectionData, sectionIndex) => {
        const sectionRef = sectionIndex + 1;
        let show = true;

        for (const index in searchQueries) {
          const query = searchQueries[index].toLowerCase();
          if (sectionData.toLowerCase().indexOf(query) === -1) {
            show = false;
            break;
          }
        }

        const logLine = document.getElementById(`log-${sectionRef}`);
        if (logLine) {
          logLine.classList.toggle("dn", !show);
          logLine.classList.toggle("flex", show);
        }
      });
    });
  });

  const filtersContainer = document.getElementById("filters-container");
  const filtersOverlay = document.getElementById("filters-overlay");

  // Listener to open filters menu
  document
    .getElementById("show-filters-toggle")
    .addEventListener("click", (e) => {
      filtersContainer.classList.toggle("dn");
      filtersOverlay.classList.toggle("dn");
      document
        .querySelector(".show-filters-caret")
        .classList.toggle("rotate-180");
    });

  // Close filters menu if user clicks outside of frame
  filtersOverlay.addEventListener("click", (e) => {
    if (e.target !== filtersOverlay) {
      return;
    }
    filtersContainer.classList.toggle("dn");
    filtersOverlay.classList.toggle("dn");
    document
      .querySelector(".show-filters-caret")
      .classList.toggle("rotate-180");
  });

  // Listener to reset filters, need to use .click() to make sure listener below fires
  document
    .getElementById("filters-reset")
    .addEventListener("click", ({ target }) => {
      target.parentElement
        .querySelectorAll(".switch-checkbox")
        .forEach((checkbox) => {
          if (!checkbox.checked) {
            checkbox.click();
          }
        });
    });

  // Listener to (un)subscribe to channels when checked
  document
    .querySelectorAll("#filters-container .switch-checkbox")
    .forEach((filterSwitch) => {
      filterSwitch.addEventListener("change", ({ target }) => {
        const slug = `private-logger-${appId}-${target.value}`;
        const channel = pusher.channel(slug);
        if (target.checked && !channel) {
          pusher.subscribe(slug).bind("log_message", bindFn);
        } else {
          pusher.unsubscribe(slug);
        }
        setFilterCount();
      });
    });
}

function setFilterCount() {
  const countEl = document.getElementById("filter-count");
  const count = document.querySelectorAll(
    "#filters-container .switch-checkbox:not(:checked)"
  ).length;

  countEl.classList.toggle("dn", count === 0);
  countEl.classList.toggle("flex", count !== 0);
  countEl.innerText = count;
}

// Check for existing filter and potentially add a class to hide log
function hideRowsIfNotInFilter(logData, rows) {
  searchQueries.forEach((query) => {
    if (logData.toLowerCase().indexOf(query.toLowerCase()) === -1) {
      for (const i in rows) {
        rows[i].classList.add("dn");
        rows[i].classList.remove("flex");
      }
      return;
    }
  });
}

module.exports = { start, hideRowsIfNotInFilter };
