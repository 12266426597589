import PusherPlatform from "@pusher/platform";
import insertEvent from "./insert-event";
import EventsTokenProvider from "./events-token-provider";
import initClearLogs from "./clear-logs";
import initEventPublisher from "./event-publisher";

let paused = false;

const start = (host, locator, instanceId) => {
  const client = new PusherPlatform.Instance({
    host,
    locator,
    serviceName: "analytics_realtime_data_forwarder",
    serviceVersion: "v1",
    tokenProvider: new EventsTokenProvider({ instanceId }),
  });

  window.addEventListener("DOMContentLoaded", () => {
    const eventsList = document.getElementById("events-list");

    const subscription = client.subscribeResuming({
      path: "/realtime?product=beams",
      listeners: {
        onError: (error) => {
          console.log("Could not subscribe to debug console", error);
        },
        onEvent: ({ body }) => {
          document.getElementById("no-events").style.display = "none";
          if (!paused) {
            insertEvent(body[0], JSON.parse(body[1]), eventsList);
          }
        },
      },
    });
  });

  initClearLogs();
  initEventPublisher();

  const pauseButton = document.getElementById("pause-logs");
  const resumeButton = document.getElementById("resume-logs");

  pauseButton.addEventListener("click", (e) => {
    paused = true;
    pauseButton.style.display = "none";
    resumeButton.removeAttribute("style");
  });

  resumeButton.addEventListener("click", (e) => {
    paused = false;
    resumeButton.style.display = "none";
    pauseButton.removeAttribute("style");
  });
};

export { start };
