import { hideRowsIfNotInFilter } from "./filters";
import getClosest from "../../utils/get-closest";

const escapeHtml = (string) => {
  return String(string).replace(/[&<>"'\/]/g, function (s) {
    return {
      "&": "&amp;",
      "<": "&lt;",
      ">": "&gt;",
      '"': "&quot;",
      "'": "&#39;",
      "/": "&#x2F;",
    }[s];
  });
};

const expandOnClick = (event) => {
  if (event.target.tagName === "PRE") return;
  const row = getClosest(event.target, "tr");
  row.querySelector(".data-cell").classList.toggle("dn");
  row.querySelector(".caret").classList.toggle("rotate-180");
};

class EventList {
  constructor(logTypes) {
    this.logTypes = logTypes;
    this.logList = {};
    this.rowsPerTbody = 250;
    this.maxRowsInConsole = 2000;
    this.logging = true;
    this.sectionClassName = `logs-1-${this.rowsPerTbody}`;
    this.logCount = 0;
    this.init();
    this.HTMLEntities = null;
  }

  async init() {
    document
      .getElementById("console-body")
      .classList.add(`logs-1-${this.rowsPerTbody}`);

    const { default: HTMLEntities } = await import(
      /* webpackChunkName: "he" */ "he"
    );
    this.HTMLEntities = HTMLEntities;
  }

  addToLogList(sectionClassName, data) {
    if (!this.logList[sectionClassName]) {
      this.logList[sectionClassName] = [];
    }

    this.logList[sectionClassName].push(data);
  }

  addLogToView(row, log) {
    const logCount = this.logCount;
    const logDetails = log.details || log.message;
    const logTime = log.time || log.date;
    const currentDebugConsoleTbody = document.querySelector(
      `.${this.sectionClassName}`
    );

    if (log.data) {
      let stringData = log.data;
      try {
        stringData = JSON.stringify(JSON.parse(log.data), null, "  ");
      } catch (e) {}
      const dataCell = document.createElement("td");
      dataCell.classList.add(
        "data-cell",
        "dn",
        "w-100",
        "overflow-x-auto",
        "ph4",
        "shadow-6",
        "br3",
        "br--bottom",
        "bg-steel",
        "order-4"
      );
      dataCell.setAttribute("id", "log-" + logCount + "-data");
      dataCell.innerHTML = `<code class="code f7 white word-wrap">
        <pre>${this.HTMLEntities.encode(stringData)}</pre>
      </code>`;

      const dataExpanderCell = document.createElement("td");
      dataExpanderCell.classList.add(
        "pv2",
        "w-10-ns",
        "pr4-ns",
        "flex",
        "items-center",
        "justify-end",
        "order-3"
      );
      dataExpanderCell.innerHTML = `<svg class="caret animate" width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.05669 6.97869L8.00002 12L12.9434 6.97869C13.4627 6.45069 13.4627 5.59135 12.9434 5.06335C12.424 4.53535 11.5774 4.53535 11.0574 5.06335L8.00002 8.16936L4.94202 5.06335C4.69002 4.80735 4.35535 4.66669 3.99935 4.66669C3.64335 4.66669 3.30869 4.80735 3.05669 5.06335C2.53669 5.59135 2.53669 6.45135 3.05669 6.97869Z" fill="currentColor"/></svg>`;

      row.classList.add("pointer", "hover-bg-snow-light", "hover-dragonfruit");
      row.style.maxWidth = "59rem";
      row.onclick = expandOnClick;

      const logData = [
        log.type,
        this.logTypes[log.type].label,
        log.socket_id,
        logTime,
        logDetails,
        log.data.replace(/(\r\n|\n|\r)/gm, ""),
      ].join(" ");
      this.addToLogList(this.sectionClassName, logData);
      hideRowsIfNotInFilter(logData, [row, dataCell]);
      row.insertAdjacentElement("beforeend", dataExpanderCell);
      row.insertAdjacentElement("beforeend", dataCell);
      currentDebugConsoleTbody.insertAdjacentElement("afterbegin", row);
    } else {
      const logData = [
        log.type,
        this.logTypes[log.type].label,
        log.socket_id,
        logTime,
        logDetails,
      ].join(" ");
      this.addToLogList(this.sectionClassName, logData);
      hideRowsIfNotInFilter(logData, [row]);
      currentDebugConsoleTbody.insertAdjacentElement("afterbegin", row);
    }
  }

  processLog(log) {
    if (!this.logging) {
      return;
    }
    this.logCount++;
    const logCount = this.logCount;
    const logRowElement = this.constructLogRowElement(log);
    const emptyElement = document.getElementById("console-body-empty-state");
    if (emptyElement) {
      emptyElement.style.display = "none";
    }
    if (logCount > 1 && logCount % this.rowsPerTbody == 1) {
      // Group logs rows in sections of size rowsPerTbody so that they can be
      // removed as a chunk when the total gets sufficiently large
      this.addNewTbodyToDebugConsole();
      if (logCount > this.maxRowsInConsole) {
        this.removeOldLogs();
      }
    }
    this.addLogToView(logRowElement, log);
  }

  constructLogRowElement(log) {
    const logDataPresent = !!log.data;
    const logType = this.logTypes[log.type];

    const typeCell = `<td class="pl2 pl3-ns pv3 w-50 w-20-ns order-0">
      <span class="truncate ph3 pv2 bg-${logType.color}-wash ${logType.color} fw6 br-pill f8">${logType.label}</span>
    </td>`;
    const detailsCell = `<td class="f8 pa3 pv2-ns ph5-ns flex-ns flex-wrap items-center w-90 w-60-ns order-2 order-1-ns">
        ${log.details && `<span class="mr4">${escapeHtml(log.details)}</span>`}
        ${
          log.socket_id
            ? `<span>Socket ID: 
          <code class="bg-snow-light br2 ba b--smoke dragonfruit-light pa1 lh-solid mh2 f9 truncate" data-attribute="<%= attribute %>">${log.socket_id}</code></span>`
            : ""
        }
       </td>`;
    const timeCell = `<td class="pv2 pr3 pr5-ns pr0-ns tr tl-ns w-50 w-10-ns order-1 order-2-ns">${log.time}</td>`;

    const newRow = document.createElement("tr");
    newRow.classList.add(
      "flex",
      "flex-wrap",
      "items-center",
      "ba",
      "b--smoke",
      "br3",
      "mb4",
      log.type
    );
    newRow.setAttribute("id", "log-" + this.logCount);
    newRow.innerHTML = typeCell + detailsCell + timeCell;

    return newRow;
  }

  addNewTbodyToDebugConsole() {
    const logCount = this.logCount;
    const tbodyClassName = `logs-${logCount}-${
      logCount + this.rowsPerTbody - 1
    }`;
    this.sectionClassName = tbodyClassName;
    const newConsoleTbody = document.createElement("tbody");
    newConsoleTbody.classList.add("console-body");
    newConsoleTbody.classList.add(tbodyClassName);
    document
      .getElementById("console-head")
      .insertAdjacentElement("afterend", newConsoleTbody);
  }

  removeOldLogs() {
    const logCount = this.logCount;
    const tbodyToRemoveStart = logCount - this.maxRowsInConsole;
    const tbodyClassName = `logs-${tbodyToRemoveStart}-${
      tbodyToRemoveStart + this.rowsPerTbody - 1
    }`;
    const tbodyToRemove = document.querySelector(`.${tbodyClassName}`);
    tbodyToRemove.parentNode.removeChild(tbodyToRemove);
    delete this.logList[tbodyClassName];
  }

  clear() {
    [].slice
      .call(document.getElementsByClassName("console-body"))
      .forEach((element) => {
        element.remove();
      });
    this.logCount = 0;
    this.sectionClassName = `logs-1-${this.rowsPerTbody}`;
    const newConsoleTbody = document.createElement("tbody");
    newConsoleTbody.classList.add("console-body");
    newConsoleTbody.classList.add(this.sectionClassName);
    document
      .getElementById("console-head")
      .insertAdjacentElement("afterend", newConsoleTbody);
    document
      .getElementById("console-body-empty-state")
      .removeAttribute("style");
  }

  toggleLogging(button) {
    button.style.display = "none";

    const oppositeButton = this.logging
      ? document.getElementById("resume-logs")
      : document.getElementById("pause-logs");
    oppositeButton.removeAttribute("style");
    this.logging = !this.logging;
  }

  getLogList() {
    return this.logList;
  }
}

export default EventList;
