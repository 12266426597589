const start = () => {
  document.addEventListener("DOMContentLoaded", () => {
    const flashPopups = [].slice.call(
      document.querySelectorAll(".flash-popup:not(.flash-popup--no-animation)")
    );

    if (flashPopups.length > 0) {
      flashPopups.forEach((flashPopup) => {
        const [closeButton] = [].slice.call(
          flashPopup.querySelectorAll(".flash-close-button")
        );

        closeButton.addEventListener("click", (e) => {
          flashPopup.classList.add("flash-popup--hide");
        });
      });
    }
  });
};

const ICON = {
  alert: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" class="db radish" width="16" height="16" role="img" aria-hidden="true"><path fill="currentColor" d="M8 0a8 8 0 100 16A8 8 0 008 0zm.667 12a.667.667 0 01-1.334 0V7.333a.667.667 0 011.334 0V12zM8 5.333a1 1 0 110-2 1 1 0 010 2z"></path></svg>`,
  error: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" class="db radish" width="16" height="16" role="img" aria-hidden="true"><path fill="currentColor" d="M8 0a8 8 0 100 16A8 8 0 008 0zm.667 12a.667.667 0 01-1.334 0V7.333a.667.667 0 011.334 0V12zM8 5.333a1 1 0 110-2 1 1 0 010 2z"></path></svg>`,
  notice: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" class="db starflower" width="16" height="16" role="img" aria-hidden="true"><path fill="currentColor" d="M8 0a8 8 0 100 16A8 8 0 008 0zm.667 12a.667.667 0 01-1.334 0V7.333a.667.667 0 011.334 0V12zM8 5.333a1 1 0 110-2 1 1 0 010 2z"></path></svg>`,
  success: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" class="db kale" width="16" height="16" role="img" aria-hidden="true"><g><path fill="currentColor" d="M13.657 2.343A8 8 0 102.343 13.66 8 8 0 0013.657 2.343zm-1.818 3.424l-4.406 5.141-3.166-2.375a.667.667 0 01.8-1.066l2.166 1.625 3.594-4.193a.668.668 0 011.012.868z"/></g></svg>`,
};

let count = 0;

const decrementCount = () => {
  setInterval(() => {
    if (count > 0) {
      count--;
    }
  }, 10000);
  // Flash messages disappear via a CSS animation after 10seconds
  // - See css/modules/_flash.pcss#4
  // We want to make sure each subsequent flash message doesn’t sit on top of last so we increment count after making one. But after 10 seconds, it will disappear and so we need to decrement the current flash message count.
};

const createFlash = (type, message, index = 0) => {
  const baseFlash = `<div class="mv5 mr5 bg-white br2 pa3 pl5 flex items-center flash-popup flash-popup--${type} shadow-5 fixed left-2 z-5" role="alert" style="top: ${Math.abs(
    5 * count
  )}rem">
  <div class="w2 h1 flex-shrink-0">
    ${ICON[type]}
  </div>
  <p class="maison f7 black measure pr4-l">${message}</p>
  <div class="ml-auto">
    <button class="bn focus-b--starflower-light hover-b--smoke-light active-b--smoke-dark pointer bg-white black hover-slate focus-bg-snow pa2 flash-close-button silver" type="button">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="db " width="16" height="16" role="img" aria-labelledby="svg-663923c6"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 6l12 12M6 18L18 6M6 6l12 12M6 18L18 6"></path><title id="svg-663923c6">Close</title></svg>
    </button>
  </div>
</div>`;
  document.querySelector("body").insertAdjacentHTML("beforeend", baseFlash);
  count++;
  decrementCount();
};

export { start, createFlash };
