import { createFlash } from "../modules/flash";

const initPusherJS = async () => {
  let pusherClass = null;

  if (
    window.pusherJsConfig !== undefined &&
    Object.keys(window.pusherJsConfig).length !== 0
  ) {
    const { default: Pusher } = await import(
      /* webpackChunkName: "pusher-js" */ "pusher-js"
    );

    pusherClass = new Pusher(
      window.pusherJsConfig.key,
      window.pusherJsConfig.options
    );

    return pusherClass;
  } else {
    createFlash("error", "pusher-js could not be initialised");
  }

  return pusherClass;
};

export { initPusherJS };
