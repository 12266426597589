import "../css/application.css";
import * as Sentry from "@sentry/browser";
import Rails from "rails-ujs";

require("../modules/add-animation").start();
require("../modules/app-menu").start();
require("../modules/color-mode-toggle").start();
require("../modules/copy").start();
require("../modules/chartkick").start();
require("../modules/disabled-links").start();
require("../modules/download").start();
require("../modules/dropdown").start();
require("../modules/feedback").start();
require("../modules/fetch-device-totals").start();
require("../modules/fetch-usage").start();
require("../modules/flash").start();
require("../modules/form-validation").start();
require("../modules/getting-started").start();
require("../modules/ghost-animation").start();
require("../modules/map-key-press-to-element-click").start();
require("../modules/menu").start();
require("../modules/modal").start();
require("../modules/more-toggle").start();
require("../modules/repeatable-sections").start();
require("../modules/snippets").start();
require("../modules/switch-radio").start();
require("../modules/tabs").start();
require("../modules/test-usage-summary-endpoint").start();
require("../modules/trash-animation").start();
require("../modules/wizard").start();

// Stimulus controllers
import "../controllers";

Rails.start();

window.beamsBilling = require("../modules/stripe");
window.beamsDebugConsole = require("../modules/beams-debug-console");
window.beamsQuickstart = require("../modules/beams-quickstart");
window.debugConsole = require("../modules/debug-console");
window.channelsBilling = require("../modules/chargify-statements");
window.stats = require("../modules/stats");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

window.sentryInit = Sentry.init;
window.sentry = Sentry;