import { createFlash } from "./flash";
import getClosest from "../utils/get-closest";
import { setLoading, unsetLoading } from "../utils/loading-button";

const sendTestSummary = async (e) => {
  e.preventDefault();
  const button =
    e.target.tagName.toLowerCase() === "button"
      ? e.target
      : getClosest(e.target, "button");
  const form = getClosest(e.target, "form");
  const { testAction } = button.dataset;

  setLoading(button);

  const response = await fetch(testAction, {
    method: "POST",
    mode: "cors",
    credentials: "same-origin",
    body: new FormData(form),
  });

  if (response.ok) {
    createFlash("success", "Test summary sent");
  } else {
    createFlash("error", "Something went wrong please try again");
  }
  unsetLoading(button);
};

const start = () => {
  document.addEventListener("DOMContentLoaded", () => {
    const testUsageSummaryButtons = [].slice.call(
      document.querySelectorAll(".test-usage-button")
    );

    testUsageSummaryButtons.forEach((button) => {
      button.addEventListener("click", sendTestSummary);
    });
  });
};

export { start };
