const DEVICE_MAP = {
  ios: "apns",
  android: "fcm",
  web: "web",
  safari: "safari",
};

module.exports.start = () => {
  document.addEventListener("DOMContentLoaded", () => {
    const deviceTotals = [].slice.call(
      document.querySelectorAll(".registered-device-total")
    );

    if (deviceTotals.length > 0) {
      fetch(deviceTotals[0].dataset.endpoint, {
        method: "GET",
        mode: "cors",
        credentials: "same-origin",
      })
        .then((response) => response.json())
        .then((totals) => {
          deviceTotals.forEach((deviceTotal) => {
            const total = totals[DEVICE_MAP[deviceTotal.dataset.device]];
            deviceTotal
              .querySelector(".registered-device-total--loading")
              .remove();
            if (total > 0) {
              const countEl = deviceTotal.querySelector(
                ".registered-device-total--count"
              );
              countEl.querySelector(
                ".registered-device-total--number"
              ).innerText = new Intl.NumberFormat().format(total);
              if (total === 1) {
                countEl.innerHTML += ' device registered';
              } else {
                countEl.innerHTML += ' devices registered';
              }

              countEl.classList.remove("dn");
            } else {
              deviceTotal
                .querySelector(".registered-device-total--get-started")
                .classList.remove("dn");
            }
          });
        })
        .catch((err) => {
          console.error(`Error updating device totals: ${err}`);
        });
    }
  });
};
