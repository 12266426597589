import { initPusherJS } from "../utils/init-pusher-js";

const start = () => {
  document.addEventListener("DOMContentLoaded", async () => {
    const connectionDot = document.querySelector(
      "#connection_state .status-dot"
    );
    const connectionText = document.querySelector("#connection_state .status");

    if (connectionDot !== null && connectionText !== null) {
      const pusher = await initPusherJS();
      const channel = pusher.subscribe("my-channel");
      channel.bind("my-event", function (data) {
        alert(JSON.stringify(data));
        analytics.track("getting_started", "message sent to client");
      });

      pusher.connection.bind("connected", function () {
        connectionDot.classList.add("success", "connected");
        connectionText.classList.add("kale");
        connectionText.innerText = "Connected";
      });

      pusher.connection.bind("disconnected", function () {
        connectionDot.classList.add("info");
        connectionText.classList.add("pumpkin");
        connectionText.innerText = "Disconnected";
      });

      pusher.connection.bind("failed", function () {
        connectionDot.classList.add("error");
        connectionText.classList.add("radish");
        connectionText.innerText = "Not supported in this browser";
      });

      pusher.connection.bind("unavailable", function () {
        connectionDot.classList.add("error");
        connectionText.classList.add("radish");
        connectionText.innerText = "Unavailable";
      });
    }
  });
};

export { start };
