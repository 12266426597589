module.exports.start = () => {
  document.addEventListener("DOMContentLoaded", () => {
    const inputsToSubmit = [].slice.call(
      document.querySelectorAll(".submit-on-change")
    );

    inputsToSubmit.forEach((input) => {
      input.addEventListener("change", (e) => {
        e.target.form.submit();
      });
    });
  });
};
