var PLACEHOLDERS = {
  feedbackType_bug:
    "Please describe for us:\n\nWhat you were trying to do?\n\nWhat you expected to happen?\n\nWhat actually happened?\n",
  feedbackType_feature: "What feature(s) do you think we are missing?\n",
  feedbackType_other: " ",
};

module.exports.start = () => {
  document.addEventListener("DOMContentLoaded", () => {
    const feedbackTextArea = document.getElementById("feedback");
    const feedbackRadios = [].slice.call(
      document.querySelectorAll("input[name='feedbackType']")
    );

    feedbackRadios.forEach((radio) => {
      radio.addEventListener("click", (e) => {
        feedbackTextArea.value = PLACEHOLDERS[e.target.id];
      });
    });
  });
};
