const start = () => {
  document.addEventListener("DOMContentLoaded", async () => {
    const copyButtons = [].slice.call(document.querySelectorAll(".copyable"));

    if (copyButtons.length > 0) {
      const { default: Clipboard } = await import(
        /* webpackChunkName: "clipboard-js" */ "clipboard"
      );

      var clipboard = new Clipboard(".copyable", {
        text: (trigger) => {
          return document
            .querySelector(trigger.dataset.clipboardTarget)
            .innerText.trim();
        },
      });

      clipboard.on("success", (event) => {
        analytics.track("code_snippet", "copied");
        event.clearSelection();
        event.trigger.children[1].innerText = "Copied";
        window.setTimeout(() => {
          event.trigger.children[1].innerText = "Copy";
        }, 2000);
      });

      clipboard.on("error", (event) => {
        event.trigger.children[1].innerText = "Press ⌘+C to copy";
        window.setTimeout(function () {
          event.trigger.children[1].innerText = "Copy";
        }, 2000);
      });
    }
  });
};

export { start };
