const ROW_LIMIT = 100;

const unixTimestampToTime = (timestamp) =>
  Intl.DateTimeFormat("default", {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  }).format(new Date(timestamp * 1000));

const insertEvent = (type, attributes, parentElement) => {
  const eventsListChildrenCount = document.getElementById("events-list")
    .childElementCount; // template rows

  // Start deleting rows once we hit limit but don’t delete template rows
  if (parentElement.childElementCount >= ROW_LIMIT + eventsListChildrenCount) {
    parentElement.children[ROW_LIMIT - 1].remove();
  }

  const row = document.getElementById(type).cloneNode(true);
  row.removeAttribute("id");
  const timeElement = row.querySelector("time");
  const currentDatetime = new Date();

  timeElement.innerText = new Intl.DateTimeFormat("default", {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  }).format(currentDatetime);
  timeElement.setAttribute(
    "datetime",
    new Intl.DateTimeFormat().format(currentDatetime)
  );
  timeElement.setAttribute(
    "title",
    new Intl.DateTimeFormat().format(currentDatetime)
  );
  delete attributes["instance_id"];

  Object.keys(attributes).forEach((key) => {
    const attributeTableCell = row.querySelector(`[data-attribute="${key}"]`);
    if (attributeTableCell !== null) {
      attributeTableCell.innerText =
        key === "opening_time"
          ? unixTimestampToTime(attributes[key])
          : attributes[key];
    }
  });

  row.classList.remove("dn");
  row.classList.add("flex", "event-row");
  parentElement.insertAdjacentElement("afterbegin", row);
};

export default insertEvent;
