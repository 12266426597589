const getClosest = require("../utils/get-closest");

const remove = (event) =>
  getClosest(event.target, ".repeatable-section").remove();

const incrementFieldAttributes = (element, count) => {
  [].slice.call(element.querySelectorAll("input, label")).forEach((element) => {
    if (element.id) {
      element.id = element.id.replace(/\d/, count);
      element.value = "";
    }
    if (element.htmlFor) {
      element.htmlFor = element.htmlFor.replace(/\d/, count);
    }
  });
};

module.exports.start = () => {
  document.addEventListener("DOMContentLoaded", () => {
    const repeatButtons = [].slice.call(
      document.querySelectorAll(".repeatable-section-add")
    );

    if (repeatButtons.length > 0) {
      repeatButtons.forEach((repeatButton) => {
        repeatButton.addEventListener("click", (e) => {
          const clone = document
            .getElementById(e.target.dataset.target)
            .cloneNode(true);
          [].slice.call(
            clone.querySelectorAll(".repeatable-section-remove")
          )[0].onclick = remove;

          incrementFieldAttributes(clone, e.target.dataset.count);
          e.target.insertAdjacentElement("beforebegin", clone);
          e.target.dataset.count = parseInt(e.target.dataset.count) + 1;
        });
      });
    }

    const removeButtons = [].slice.call(
      document.querySelectorAll(".repeatable-section-remove")
    );

    if (removeButtons.length > 0) {
      removeButtons.forEach((removeButton) => {
        removeButton.addEventListener("click", (e) => remove(e));
      });
    }
  });
};
