const getClosest = require("../utils/get-closest");

module.exports.start = () => {
  document.addEventListener("DOMContentLoaded", () => {
    const tabToggles = [].slice.call(document.querySelectorAll(".tab-toggle"));
    const tabContents = [].slice.call(
      document.querySelectorAll(".tab-content")
    );

    tabToggles.forEach((toggle) => {
      toggle.addEventListener("click", (e) => {
        e.preventDefault();
        tabContents.forEach((tab) => {
          tab.classList.toggle(
            "tab-content--active",
            tab.id === e.target.href.split("#")[1]
          );
        });
        tabToggles.forEach((tabToggle) => {
          tabToggle.classList.toggle(
            "tab--active",
            tabToggle.href === e.target.href
          );
        });

        document
          .querySelectorAll(
            `#${e.target.href.split("#")[1]} input:not([type='hidden'])`
          )[0]
          .focus();
      });
    });
  });
};
