import { setLoading, unsetLoading } from "../../utils/loading-button";

const submit = async (e) => {
  e.preventDefault();
  const button = e.target.querySelector("button");
  setLoading(button);

  const response = await fetch(e.target.action, {
    method: "POST",
    mode: "cors",
    credentials: "same-origin",
    body: new FormData(e.target),
  });

  const result = await response.json();
  let errorLabel = document.getElementById(`${e.target.id}-invalid-json`);

  if (result.status !== 200) {
    if (errorLabel === null) {
      errorLabel = document.createElement("p");
      errorLabel.id = `${e.target.id}-invalid-json`;
      errorLabel.classList.add("f7", "fw6", "radish", "ma0");
      e.target
        .querySelector("[for='data']")
        .insertAdjacentElement("beforeend", errorLabel);
      document
        .getElementById("data")
        .classList.add(
          "b--radish",
          "focus-b--radish",
          "hover-b--radish",
          "focus-bs-radish"
        );
    }

    errorLabel.innerText = result.error;
  } else if (errorLabel !== null) {
    errorLabel.remove();
    document
      .getElementById("data")
      .classList.remove(
        "b--radish",
        "focus-b--radish",
        "hover-b--radish",
        "focus-bs-radish"
      );
  }

  unsetLoading(button);
};

function initEventPublisher() {
  document
    .getElementById("interest-publisher")
    .addEventListener("submit", submit);
  document.getElementById("user-publisher").addEventListener("submit", submit);
}

export default initEventPublisher;
