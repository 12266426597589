module.exports.start = () => {
  document.addEventListener("DOMContentLoaded", () => {
    const appMenuToggle = document.getElementById("app-menu-toggle");

    if (appMenuToggle) {
      const appMenu = document.getElementById(appMenuToggle.dataset.target);
      menuItems = [].slice.call(appMenu.querySelectorAll("li"));

      appMenuToggle.addEventListener("click", (e) => {
        appMenu.setAttribute(
          "aria-expanded",
          appMenu.getAttribute("aria-expanded") !== "true"
        );

        menuItems.forEach((menuItem) => {
          if (!menuItem.classList.contains("active")) {
            menuItem.classList.toggle("dn");
          }
        });
      });
    }
  });
};
