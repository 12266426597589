import { createFlash } from "../flash";
import getClosest from "../../utils/get-closest";
import { setLoading, unsetLoading } from "../../utils/loading-button";

const sendEvent = (event) => {
  return fetch(event.target.action, {
    method: "POST",
    mode: "cors",
    credentials: "same-origin",
    body: new FormData(event.target),
  })
    .then((res) => {
      if (!res.ok) {
        throw new Error(`Unexpected response code: ${res.status}`);
      }
    })
    .catch((err) => {
      throw new Error(`Error sending event: ${err}`);
    });
};

export { start };

function start(accountId, eventPath) {
  const eventCreatorForm = document.querySelector(".event-creator-form");

  eventCreatorForm.addEventListener("submit", (e) => {
    e.preventDefault();

    if (e.target.elements.event_channel.value.match(/[^A-Za-z0-9_\-=@,.;]/)) {
      return createFlash("error", "Invalid channel name");
    }
    if (e.target.elements.event_event_name.value.match(/^client-\w+/)) {
      return createFlash(
        "error",
        `No client events, remove <code class="bg-snow br1 dragonfruit-light pv1 ph2">client-</code> prefix`
      );
    }

    analytics.track("event_sent_from_debug_console", {
      account_id: accountId,
    });

    const button = e.target.querySelector("button");
    setLoading(button);

    sendEvent(e)
      .then((res) => {
        createFlash("success", "Event sent successfully");
      })
      .catch((err) => {
        createFlash("error", "Error sending event");
      })
      .finally(() => {
        unsetLoading(button);
      });
  });

  const eventCreator = document.getElementById("event-creator");
  const eventCreatorToggles = [].slice.call(
    document.querySelectorAll(".event-creator-toggle")
  );
  const eventCreatorToggleCaret = document.querySelector(
    ".event-creator-toggle--caret"
  );
  eventCreatorToggles.forEach((toggle) => {
    toggle.addEventListener("click", (e) => {
      eventCreatorForm.classList.toggle("dn");
      eventCreator.classList.toggle("bg-snow");
      eventCreator.classList.toggle("b--snow");
      eventCreatorToggleCaret.classList.toggle("rotate-180");
      eventCreator.querySelectorAll("input:not([type='hidden'])")[0].focus();
    });
  });
}
