const createFileDownload = (filename, text) => {
  const element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(text)
  );

  element.setAttribute("download", filename);
  element.style.display = "none";
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

module.exports.start = () => {
  document.addEventListener("DOMContentLoaded", () => {
    const downloadButtons = [].slice.call(
      document.querySelectorAll(".download-button")
    );

    if (downloadButtons.length > 0) {
      downloadButtons.forEach((downloadButton) => {
        downloadButton.addEventListener("click", (e) => {
          analytics.track("code_snippet", "downloaded");
          createFileDownload(
            downloadButton.dataset.filename,
            document.getElementById(downloadButton.dataset.target).innerText
          );
        });
      });
    }
  });
};
