// Charts for overview pages https://github.com/ankane/chartkick
const start = () => {
  document.addEventListener("DOMContentLoaded", async () => {
    if (window.hasCharts === true) {
      await import(/* webpackChunkName: "chartkick" */ "chartkick/chart.js");

      if (Chartkick.adapters[0].name === "chartjs") {
        const chart = Chartkick.adapters[0].library;

        chart.defaults.font = {
          family:
            "'Maison Neue', -apple-system, BlinkMacSystemFont, -system-ui, 'avenir next', avenir, helvetica, 'helvetica neue', ubuntu, roboto, noto, 'segoe ui', arial, sans-serif",
          size: 12,
          weight: "bold",
        };
        chart.defaults.color = "#a3aacc";
        chart.defaults.borderColor = "#d6daed";
      }
    }
  });
};

export { start };
