const create = async (stripeKey, formElementID) => {
  const { loadStripe } = await import(
    /* webpackChunkName: "stripe" */ "@stripe/stripe-js/pure"
  );

  const stripe = await loadStripe(stripeKey);
  const paymentForm = document.getElementById(formElementID);
  const elements = stripe.elements();
  const style = {
    base: {
      color: "#32325d",
      fontFamily:
        '"Maison Neue", -apple-system, BlinkMacSystemFont, -system-ui, "avenir next", avenir, helvetica, "helvetica neue", ubuntu, roboto, noto, "segoe ui", arial, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#a3aacc",
      },
    },
    invalid: {
      color: "#d9215e",
      iconColor: "#d9215e",
    },
  };

  const card = elements.create("card", { style });
  card.mount("#card-element");

  card.addEventListener("change", ({ error }) => {
    const displayError = document.getElementById("card-errors");
    if (error) {
      displayError.textContent = error.message;
    } else {
      displayError.textContent = "";
    }
  });

  document.getElementById("country_code").addEventListener("change", (e) => {
    document.getElementById("country-prefix").innerText = e.target.value;
  });

  paymentForm.addEventListener("submit", (e) => {
    e.preventDefault();
    stripe
      .createToken(card, {
        address_country: paymentForm.elements["country_code"].value,
        address_line1: paymentForm.elements["address"].value,
        name: paymentForm.elements["card_holder_name"].value,
      })
      .then((result) => {
        if (result.error) {
          const errorElement = document.getElementById("card-errors");
          errorElement.textContent = result.error.message;
        } else {
          const hiddenInput = document.getElementById("stripe_token");
          hiddenInput.setAttribute("value", result.token.id);

          paymentForm.submit();
        }
      });
  });
};

const confirm = async (stripeKey, secret, redirect) => {
  const { loadStripe } = await import(
    /* webpackChunkName: "stripe" */ "@stripe/stripe-js/pure"
  );
  const stripe = await loadStripe(stripeKey);
  stripe.confirmCardPayment(secret).then((result) => {
    document.getElementById("3ds-loading").remove();
    if (result.error) {
      document.getElementById("3ds-failure").classList.remove("dn");
    } else {
      document.getElementById("3ds-success").classList.remove("dn");
      window.location.href = redirect;
    }
  });
};

export { create, confirm };
