module.exports.start = () => {
  document.addEventListener("DOMContentLoaded", () => {
    const modalButtons = [].slice.call(
      document.querySelectorAll(".modal-button-toggle")
    );
    const mainContent = document.getElementsByClassName("app")[0];

    const closeWhenClickingOutsideModal = (modal) => {
      // I’d like to use click here but it was causing issues for people in Chrome who highlight the text and trigger a mouseup event outside the modal. Safari and Firefox handle this fine.
      modal.addEventListener("mousedown", (e) => {
        if (e.target !== modal) {
          return;
        }
        modal.classList.remove("active");
        mainContent.classList.remove("css-blur");
      });
    };

    if (modalButtons.length > 0) {
      modalButtons.forEach((modalButton) => {
        modalButton.addEventListener("click", (e) => {
          e.preventDefault();
          const modal = document.getElementById(modalButton.dataset.modalId);
          modal.classList.toggle("active");
          mainContent.classList.toggle("css-blur");
          modal.setAttribute(
            "aria-hidden",
            !modal.classList.contains("active")
          );
          modal
            .querySelectorAll(
              "input:not([type='hidden']), button:not(.tooltip-icon)"
            )[0]
            .focus();

          closeWhenClickingOutsideModal(modal);
        });
      });
    }

    // Sometimes modals show automatically when the page loads in this case
    // we need to set an event listener for clicks outside the modal
    const activeOnLoadModal = document.querySelector(".unified-modal.active");
    if (activeOnLoadModal) closeWhenClickingOutsideModal(activeOnLoadModal);
  });
};