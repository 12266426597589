const getClosest = require("../utils/get-closest");

module.exports.start = () => {
  document.addEventListener("DOMContentLoaded", () => {
    const formsToValidate = [].slice.call(
      document.querySelectorAll("form[data-validate='true']")
    );

    formsToValidate.forEach((form) => {
      form.addEventListener("submit", (e) => {
        e.preventDefault();

        const fields = [].slice.call(
          e.target.querySelectorAll("[data-validate='true']")
        );

        const checkboxes = fields.filter((field) => field.type === "checkbox");

        const invalidCheckboxes =
          checkboxes.filter((checkbox) => checkbox.checked === true).length ===
          0;

        if (invalidCheckboxes) {
          getClosest(checkboxes[0], "fieldset")
            .querySelector(".hint")
            .classList.add("error");
        } else {
          e.target.submit();
        }
      });
    });
  });
};
