import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "connections",
    "connectionsFormatted",
    "connectionsFormattedWords",
    "messages",
    "messagesFormatted",
    "messagesFormattedWords",
  ];
  static values = {
    connections: Number,
    messages: Number,
  };

  initialize() {
    this.connectionsValue = this.connectionsTarget.value;
    this.messagesValue = this.messagesTarget.value;
  }

  update_connections() {
    this.connectionsValue = this.connectionsTarget.value;
  }

  update_messages() {
    this.messagesValue = this.messagesTarget.value;
  }

  connectionsValueChanged() {
    this.updateView("connections");
  }

  messagesValueChanged() {
    this.updateView("messages");
  }

  updateView(field) {
    if (this[`${field}Value`] !== this[`${field}Value`]) {
      // if it’s NaN
      this[`${field}FormattedTarget`].innerText = "0";
      this[`${field}FormattedWordsTarget`].innerText = "0";
    } else {
      this[`${field}FormattedTarget`].innerText = this[
        `${field}Value`
      ].toLocaleString();
      this[`${field}FormattedWordsTarget`].innerText = this.number_to_words(
        this[`${field}Value`]
      );
    }
  }

  number_to_words(number) {
    const noOfDigits = `${number}`.length;

    if (noOfDigits >= 16) {
      return `~${Math.round(number / 1e15).toLocaleString()} quadrillion`;
    } else if (noOfDigits >= 13) {
      return `~${Math.round(number / 1e12)} trillion`;
    } else if (noOfDigits >= 10) {
      return `~${Math.round(number / 1e9)} billion`;
    } else if (noOfDigits >= 7) {
      return `~${Math.round(number / 1e6)} million`;
    } else if (noOfDigits >= 5) {
      return `~${Math.round(number / 1000).toLocaleString()} thousand`;
    } else {
      return number.toLocaleString();
    }
  }
}
